import React, { Component } from 'react';
import './Input.css';

class Input extends Component {
   constructor(props) {
      super(props);

      this.state = {
         'hasError': false
      }

      this.handleChange = this.handleChange.bind(this);
   }

   handleChange(e) {
      this.showInputError(e);
      this.props.onChange(this.props.target, e.target.value);
   }

   showInputError(e) {
      var refName = e.target.name;

      if (!e.target.required) {
         return true;
      }

      const validity = e.target.validity;
      const error = document.getElementById(refName + '_error');

      if (!validity.valid) {
         this.setState({'hasError': true});

         if (validity.typeMismatch) {
            error.textContent = this.props.label + ' is an invalid format.';
         } else if (validity.patternMismatch) {
            error.textContent = this.props.label + ' contains invalid characters.';
         } else if (validity.valueMissing) {
            error.textContent = this.props.label + ' is required.';
         }

         return false;
      }

      error.textContent = '';
      this.setState({'hasError': false});
      return true;
   }

   render() {
      return (
         <div className="field-wrapper">
            <label htmlFor={this.props.id} id={this.props.id + '_label'} className={this.props.required ? 'label required' : 'label'}>{this.props.label}</label>
            <input
               type={this.props.type}
               className={this.state.hasError ? 'input has-error' : 'input'}
               placeholder={this.props.placeholder}
               value={this.props.value}
               id={this.props.id}
               name={this.props.id}
               ref={this.props.id}
               disabled={this.props.disabled}
               onChange={this.handleChange}
               required={this.props.required}
               pattern={this.props.pattern}
            />
            <span className="error" id={this.props.id + '_error'}></span>
         </div>
      )
   }
}
export default Input;
