import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {authContext} from '../../../adalConfig';
import {toast} from 'react-toastify';
import update from 'immutability-helper';

import Form from '../../../components/elements/Form';
import Input from '../../../components/elements/Input';
import SelectBrand from '../../../components/elements/SelectBrand';
import SelectChannel from '../../../components/elements/SelectChannel';
import CheckboxDirectors from '../../elements/CheckboxDirectors';
import CheckboxMarkets from '../../elements/CheckboxMarkets';
import CheckboxMonths from '../../../components/elements/CheckboxMonths';
import CheckboxDepartments  from '../../../components/elements/CheckboxDepartments';

import './CampaignForm.css';
import CheckboxGeneric from "../../elements/CheckboxGeneric";
import _displayConfiguration from "../../../json/displayConfiguration";


class CampaignForm extends React.Component {
    constructor(props) {
        super(props);
        const {productGroupId: {name: productGroup}} = JSON.parse(localStorage.getItem('fiscalYearPlan'));
        let hideDirectors = !_displayConfiguration[productGroup].hasDirector;
        const FISCAL_YEAR_PLAN_ID = JSON.parse(localStorage.getItem('fiscalYearPlan'))._id;
        this.state = {
            fiscalYearPlanId: FISCAL_YEAR_PLAN_ID,
            prettyName: "",
            departments: [],
            markets: [],
            directors: [],
            hideDirectors: hideDirectors,
            brandId: "",
            channelId: "",
            activeMonths: [],

            _activeTab: 0,
            _isLoading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleValidate = this.handleValidate.bind(this);
        // this.handlePost = this.handlePost.bind(this);
    }

    handleValidate() {
        let {
            prettyName,
            departments,
            markets,
            brandId,
            channelId,
            directors
        } = this.state

        if (!prettyName) {
            toast("Please Enter a Campaign Name", {className: "notification error"});
            return true
        }
        if (!brandId) {
            toast("Please Select A Brand", {className: "notification error"});
            return true
        }
        if (!channelId) {
            toast("Please Select A Channel", {className: "notification error"});
            return true
        }
        if (departments.length <= 0) {
            toast("Please Select A Department", {className: "notification error"});
            return true
        }
        if (markets.length <= 0) {
            toast("Please Select A Market", {className: "notification error"});
            return true
        }

        if (!this.state.hideDirectors && directors.length === 0) {
            toast("Please Select A Director", {className: "notification error"});
            return true
        }
        return false
    }

    handlePost() {
        const token = localStorage.getItem('accessToken');
        if (!token) return;

        // remove ui properties
        let data = update(this.state, {$unset: ['_activeTab']});

        data.directors = data.directors.map((d)=>{return {'id': d.split('|')[0], 'marketId': d.split('|')[1]}});
        const FISCAL_YEAR_PLAN_ID = JSON.parse(localStorage.getItem('fiscalYearPlan'))._id;

        this.setState({_isLoading: true});
        // post
        if (data) {
            fetch(process.env.REACT_APP_API_URL + 'programs', {
                method: 'post',
                headers: {
                    'Authorization': token,
                    'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then((response) => {
                if (response.status === 498) {
                    authContext._renewIdToken((err, token) => {
                        this.handlePost()
                    })
                } else if (response.ok) {
                    return response.json()
                } else {
                    return response.json().then(Promise.reject.bind(Promise));
                }
            }).then((json) => {
                if (!json) return;
                toast("Programs Created Successfully", {className: "notification success"});
                this.props.handleRefresh();
                this.setState({_isLoading: false});
                this.props.handleCancel();
            }).catch((error) => {
                if (error.code && error.code === 11000) {
                    toast("Duplicate name found.", {className: "notification error"});
                } else {
                    toast(error.message, {className: "notification error"});
                }
                this.setState({_isLoading: false})
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        let hasErrors = this.handleValidate()
        if (!hasErrors) {
            this.handlePost()
        }
    }

    handleChange(target, value) {
        this.setState({
            [target]: value
        });
    }

    render() {

        return (
            <Form className="form campaign">

                <Tabs selectedTabClassName="is-active" selectedIndex={this.state._activeTab}
                      onSelect={_activeTab => this.setState({_activeTab: _activeTab})}>

                    <div className="form-header">

                        <TabList className="tab-links">
                            <Tab className="tab">New Campaign Details</Tab>
                        </TabList>

                        <button className="cancel" onClick={this.props.handleCancel}>Cancel</button>

                    </div>

                    <div className="form-body">

                        <TabPanel>

                            <div className="form-section">
                                <div className="input-wrapper full">
                                    <Input label="Campaign Name" id="campaignName" value={this.state.prettyName}
                                           type="text" target="prettyName" onChange={this.handleChange} required/>
                                </div>
                                <div className="input-wrapper half">
                                    <SelectBrand label="Brand" id="brand" value={this.state.brandId} target="brandId"
                                                 onChange={this.handleChange}/>
                                </div>
                                <div className="input-wrapper half">
                                    <SelectChannel label="Channel" id="channel" value={this.state.channelId}
                                                   target="channelId" onChange={this.handleChange}/>
                                </div>
                            </div>

                            <div className="form-section">
                                <CheckboxMonths
                                    label="Months Active"
                                    target="activeMonths"
                                    actualValue={this.state.activeMonths}
                                    value={this.state.activeMonths}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-section">
                                <CheckboxDepartments
                                    label="Select Department(s)"
                                    target="departments"
                                    actualValue={this.state.departments}
                                    value={this.state.departments}
                                    onChange={this.handleChange}/>
                            </div>

                            <div className="form-section">
                                <CheckboxMarkets
                                    label="Select Market(s)"
                                    target="markets"
                                    actualValue={this.state.markets}
                                    value={this.state.markets}
                                    onChange={this.handleChange}/>
                            </div>

                            <div className="form-section" hidden={this.state.hideDirectors}>
                                <CheckboxDirectors
                                    label="Select Director(s)"
                                    target="directors"
                                    actualValue={this.state.directors}
                                    value={this.state.directors}
                                    onChange={this.handleChange}
                                    matchMarkets={this.state.markets}/>
                            </div>

                            <div className="form-footer">
                                <button className="button submit" disabled={this.state._isLoading}
                                        onClick={this.handleSubmit}>Release Program(s)
                                </button>
                            </div>

                        </TabPanel>

                    </div>

                </Tabs>

            </Form>
        )
    }
}

export default CampaignForm;
