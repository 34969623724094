import React from 'react';
import './RequestDelete.css';

class RequestDelete extends React.Component {
   render() {
      return (
         <div className="modal">
            <h5 className="modal-header">
               This Action Cannot be Undone.
            </h5>
            <div className="modal-body">
               Are you sure you want to continue?
            </div>
            <div className="modal-actions">
               <a onClick={this.props.handleCancel}>Cancel</a>
               <a onClick={this.props.handleDelete}>Delete</a>
            </div>
         </div>
      )
   }
}

export default RequestDelete;
