import React, { Component } from 'react';

import MonthItem from '../../../components/elements/MonthItem';
import {addCommas} from "../../../utils";

import './PlanGrid.css';


class PlanGrid extends Component {
   constructor(props) {
      super(props);

      this.state = {
         countTotal: "",
         kpiTotal: "",
         costTotal: ""
      };

      this.calculateTotals = this.calculateTotals.bind(this);
   }

   calculateTotals() {
      const data = this.props.data;

      let countTotal = 0;
      let kpiTotal = 0;
      let costTotal = 0;

      data.forEach((month, i) => {
         countTotal += +month.count;
         kpiTotal += +month.kpiTotal;
         costTotal += +month.eventCostTotal;
      });

      this.setState({
         countTotal: Math.round(countTotal),
         kpiTotal: Math.round(kpiTotal),
         costTotal: Math.round(costTotal)
      });
   }

   componentDidMount() {
      this.calculateTotals();
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.data !== this.props.data) {
         this.calculateTotals();
      }
   }

   render() {
      const data = this.props.data;

      return (
         <div className="grid">

            <div className="grid-labels">
               <span>Event Count</span>
               <span>Projected Consumer Reach</span>
               <span>Total Event Sampling Cost ($)</span>
            </div>

            {data.map(function(month, index) {
               return <MonthItem editable={this.props.editable} data={month} index={index} key={index}
                                 onUpdate={this.props.onUpdate} onRecalculate={this.props.onRecalculate}
                                 onCostUpdate={this.props.onCostUpdate} specialEvent={this.props.specialEvent}/>
            }, this)}

            <div className="grid-totals">
               <div className="grid-month">
                  <span className="header">Total</span>
                  <input disabled type="text" value={addCommas(this.state.countTotal)} />
                  <input disabled type="text" value={addCommas(this.state.kpiTotal)} />
                  <input disabled type="text" value={addCommas(this.state.costTotal)} />
               </div>
            </div>

         </div>
      )
   }
}

export default PlanGrid;
