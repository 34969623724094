import React, {Component} from 'react';
import jwt from 'jsonwebtoken';

import NotAllowed from '../../../components/views/NotAllowed'

class Callback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
        this.getUserInfoFromToken = this.getUserInfoFromToken.bind(this);
        // this.getDepartmentsForUser = this.getDepartmentsForUser.bind(this);
    }

    componentDidMount() {
        this.getUserInfoFromToken()
    }

    getUserInfoFromToken() {
        localStorage.removeItem('filters');
        localStorage.removeItem('userToken');
        localStorage.removeItem('fiscalYearPlans');
        localStorage.removeItem('fiscalYearPlan');
        localStorage.removeItem('department');
        localStorage.removeItem('accessToken');

        const token = localStorage.getItem('adal.idtoken');
        if (!token) return;

        fetch(process.env.REACT_APP_API_URL + `roles/user/login`, {
            method: 'get',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(Promise.reject.bind(Promise));
            }
        }).then(async (json) => {
            let {fiscalYearPlans, fiscalYearPlan, user} = json;
            // user.departments = await this.getDepartmentsForUser(user, token);
            // set userToken
            let signedUser = jwt.sign(user, process.env.REACT_APP_ROLE_SECRET);

            localStorage.setItem('userToken', signedUser);
            localStorage.setItem('fiscalYearPlans', JSON.stringify(fiscalYearPlans));
            localStorage.setItem('fiscalYearPlan', JSON.stringify(fiscalYearPlan));
            localStorage.setItem('accessToken', json.accessToken);

            // redirect user based on role
            if (user && user.access && user.access === "admin") {
                window.location = "/pre-work/programs";
            } else if (user && user.access) {
                window.location = "/planning";
            }
        }).catch((err) => {
            this.setState({isLoading: false})
            console.log("err ===> ", err);
        });
    }

    // getDepartmentsForUser(user, token){
    //    return Promise.all(user.departments.map(department=> {
    //       if (typeof department === 'object') return department;
    //       return fetch(process.env.REACT_APP_API_URL + `departments/${department}`, {
    //          method: 'get',
    //          headers: {
    //             'Authorization': token,
    //             'Content-Type': 'application/json'
    //          }
    //       }).then(res=>res.json()).then(body=>body)
    //    }));
    // }

    render() {
        if (this.state.isLoading) {
            return null
        } else {
            return <NotAllowed/>
        }
    }
}

export default Callback;
