import React from 'react';
import './RequestBulkDelete.css';

class RequestBulkDelete extends React.Component {


   render() {
      return (
         <div className="modal">
            <h5 className="modal-header">
               This Action Cannot be Undone.
            </h5>
            <div className="modal-body">
               Are you sure you want to delete {this.props.count} program{this.props.count > 1 ? 's' : ''}?
            </div>
            <div className="modal-actions">
               <a onClick={this.props.handleCancel}>Cancel</a>
               <a onClick={this.props.handleDelete}>Delete</a>
            </div>
         </div>
      )
   }
}

export default RequestBulkDelete;
