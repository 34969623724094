import React, { Component } from 'react';
import {Checkbox, CheckboxGroup as Checkboxes} from 'react-checkbox-group';
import Months from '../../../json/months';
import './CheckboxMonths.css';

class CheckboxMonths extends Component {
   constructor() {
      super();

      this.state = {
         months: Months,
         selectAllValue: [1,2,3,4,5,6,7,8,9,10,11,12]
      }

      this.monthSelect = this.monthSelect.bind(this);
      this.toggleSelectAll = this.toggleSelectAll.bind(this);
      this.isEquivilent = this.isEquivilent.bind(this);
   }

   monthSelect(selectedMonths) {
      this.props.onChange(this.props.target, selectedMonths);
   }

   isEquivilent(arr1, arr2) {
      if (arr2 && arr1 && Array.isArray(arr2) && Array.isArray(arr1)) {
         if (Object.keys(arr2).length === Object.keys(arr1).length) {
               return arr2.reduce((result, actual) => {
               return arr1.includes(actual) && result
            }, true)
         }
         return false
      }
      return false
   }

   toggleSelectAll() {
      let areEqual = false
       const disabledMonths = CheckboxMonths.getDisabledMonths();
      let selectAllValue = [1,2,3,4,5,6,7,8,9,10,11,12].filter((v,i)=>!disabledMonths[i]);
      areEqual = this.isEquivilent(selectAllValue, this.props.actualValue)
      if (areEqual) {
         this.props.onChange(this.props.target, [])
      } else {
         this.props.onChange(this.props.target, selectAllValue)
      }
   }

    static getDisabledMonths(){
        let fiscalYear = JSON.parse(localStorage.getItem('fiscalYearPlan'));
        let disabledMonths = [false, false, false, false, false, false, false, false, false, false, false, false];
        if (fiscalYear.planningPeriods < 4) {
            let quarters = ['q1', 'q2', 'q3', 'q4'];
            for (let i = 0; i < 4; i++) {
                if (!fiscalYear.enabledPeriods[quarters[i]]){
                    for(let j=0;j<3;j++){
                        let monthIdx = (i*3)+j+fiscalYear.startsOn-1;
                        if (monthIdx > 11){
                            monthIdx -= 12;
                        }
                        disabledMonths[monthIdx] = true;
                    }
                }
            }
        }
        return disabledMonths;
    }
   render() {
       const disabledMonths = CheckboxMonths.getDisabledMonths();
       return (
         <div className="checkbox-group">

            <div className="checkbox-group-header">

               <span className="label">{this.props.label}</span>

               <label htmlFor="selectAllMonths" className="checkbox-wrapper">
                  <input type="checkbox" id="selectAllMonths" checked={this.isEquivilent(this.props.actualValue, this.state.selectAllValue)} onChange={this.toggleSelectAll} />
                  <span></span>
                  <p className="selectAll">Select All</p>
               </label>

            </div>

            <div className="checkbox-group-body">
               <Checkboxes onChange={this.monthSelect} checkboxDepth={2} value={this.props.value}>
                  {this.state.months.map(function(month, i) {
                     return (
                        <label htmlFor={month.name} className="checkbox-wrapper" key={i}>
                           <Checkbox id={month.name} value={month.value} disabled={disabledMonths[i]}/>
                           <span></span>
                           {month.prettyName}
                        </label>
                     )
                  })}
               </Checkboxes>
            </div>

         </div>

      )
   }

}
export default CheckboxMonths;
