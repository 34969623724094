import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';
import ReactGA from 'react-ga';
import App from './App';

import './index.css';

authContext.log = function(level, message, error) {
   console.log(level, message, error);
 };

ReactGA.initialize('UA-117774069-1');


runWithAdal(authContext, () => {
	ReactDOM.render((
	  <BrowserRouter>
	    <App />
	  </BrowserRouter>
	), document.getElementById('root'));
});
