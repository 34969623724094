import React, { Component } from 'react';
import moment from 'moment';
import _displayConfiguration from '../../../json/displayConfiguration.js';

import './PlanHeader.css';

class PlanHeader extends Component {
    render() {
        const fiscalYearPlan = JSON.parse(localStorage.getItem('fiscalYearPlan'));
        const productGroup = fiscalYearPlan.productGroupId.name;
        const data = this.props.data;

        return (
            <div className="tile-header" onClick={this.props.toggleCollapse}>

                <div className="market">
                   <span className="label">Market</span>
                   <h4 className="value">{data.marketName}</h4>
                </div>

                {_displayConfiguration[productGroup].showDirectors &&
                  <div className="director">
                    <span className="label">Director</span>
                    <h4 className="value">{data.directorName}</h4>
                  </div>
                }

                <div className="name">
                   <span className="label">Program</span>
                   <h4 className="value">{data.programName}</h4>
                </div>

                <div className="status">
                   <span className="label">Edits saved {moment(data.lastSaved).fromNow()}</span>
                   <span className={data.status}>{`Status: ${data.status}`}</span>
                </div>
                <button className="toggle-collapse" />
            </div>
        )
    }
}

export default PlanHeader;
