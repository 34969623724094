module.exports = {
   dbc: {
      companyName: "DBC",
      showNationalAccountsTab: true,
      showBrandTab: true,
      showCalculator: false,
      showDivisionTab: false,
      hasDirector: true,
      displayRoleText: value => {
         if (value === "division") return "region"
         if (value === "Division") return "Region"
         return value
      },
      showDirectors: true,
      planningTileActiveTab: 1
   },
   spirits: {
      companyName: "DIAGEO",
      showNationalAccountsTab: true,
      showBrandTab: true,
      showCalculator: true,
      showDivisionTab: true,
      displayRoleText: value => {
         if (value === "division") return "local sampling"
         if (value === "Division") return "Local Sampling"
         return value
      },
      hasDirector: false,
      showDirectors: false,
      planningTileActiveTab: 0
   }
}
