import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import './Pagination.css';

class Pagination extends Component {
   constructor(){
      super();
      this.state = {
         currentPage: 1
      }

      this.handleChange = this.handleChange.bind(this)
      this.handleKeyPress = this.handleKeyPress.bind(this)
      this.handleExpand = this.handleExpand.bind(this)
      this.handleCollapse = this.handleCollapse.bind(this)
   }

   componentDidUpdate(nextProps, nextState) {
      if (this.state.currentPage !== nextState.currentPage) {
         console.log("======> WE MADE IT HERE <======")
      } else if (nextProps.page !== this.state.currentPage) {
         this.setState({ currentPage: this.props.page })
      }

   }

   componentDidMount() {
      this.setState({ currentPage: this.props.page })
   }

   handleKeyPress = (e) => {
      if (e.key === 'Enter' && !isNaN(this.state.currentPage)) {
         this.props.onUpdate({page: this.state.currentPage})
      }
   }

   handleChange(e) {
      const pages = this.props.pages
      const value = e.target.value
      console.log("value ===> ", value)
      if (value === "") {
         this.setState({ currentPage: ""});
      } else if (value > pages) {
         this.setState({ currentPage: +pages});
      } else if (value < 1) {
         this.setState({ currentPage: 1});
      } else {
         this.setState({ currentPage: +value});
      }
   }
   handleExpand() {
      let tiles = document.body.querySelectorAll('.tile')
      tiles.forEach(tile => {
         tile.classList.remove("is-collapsed")
      })
   }

   handleCollapse() {
      let tiles = document.body.querySelectorAll('.tile')
      tiles.forEach(tile => {
         tile.classList.add("is-collapsed")
      })
   }
   render() {
      return (
         <div className="pagination">
            <div className="pagination-info">Showing <span>{Math.min(this.props.limit, this.props.docs)}</span> of <span>{this.props.total}</span> {this.props.label}</div>
               <Route path="/planning*" render={() =>
                  <Fragment>
                     <div className="text-button" hidden={this.props.hideButtons} onClick={this.handleExpand}>Expand All</div>
                     <div className="text-button" hidden={this.props.hideButtons} onClick={this.handleCollapse}>Collapse All</div>
                  </Fragment>}>
               </Route>
            <div className="pagination-controls">
               <span className="left-double-arrow" onClick={() => this.props.onUpdate({page: 1})}><div></div></span>
               <span className="left-single-arrow" onClick={() => this.props.onUpdate({page: +this.props.page - 1})}><div></div></span>
               <div className="page-control">
                  <span>Page</span>
                  <input value={this.state.currentPage} type="number" className="page-selector" onChange={this.handleChange} onKeyPress={this.handleKeyPress}></input>
                  <span>of <span>{this.props.pages}</span></span>
               </div>
               <span className="right-single-arrow" onClick={() => this.props.onUpdate({page: +this.props.page + 1})}><div></div></span>
               <span className="right-double-arrow" onClick={() => this.props.onUpdate({page: this.props.pages})}><div></div></span>
            </div>
         </div>
      )
   }

}
export default Pagination;
