import React, { Component } from 'react';
import {Checkbox, CheckboxGroup as Checkboxes} from 'react-checkbox-group';
import './CheckboxMarkets.css';

class CheckboxMarkets extends Component {
   constructor(props) {
      super(props);

      this.state = {
         markets: [],
      };

      this.marketSelect = this.marketSelect.bind(this);
      this.isEquivilent = this.isEquivilent.bind(this);
      this.toggleSelectAll = this.toggleSelectAll.bind(this);
   }

   marketSelect(selectedMarkets) {
      this.props.onChange(this.props.target, selectedMarkets);
   }

   componentDidMount() {
      const token = localStorage.getItem('accessToken');
      if (!token) return;
      const { _id: FISCAL_YEAR_PLAN_ID, productGroupId: { _id: productGroupId } } = JSON.parse(localStorage.getItem('fiscalYearPlan'));
      fetch(process.env.REACT_APP_API_URL + 'markets?productGroupId=' + productGroupId , {
         method: 'get',
         headers: {
            'Authorization': token,
            'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
            'Content-Type': 'application/json'
         }
      }).then((response) => {
         return response.json()
      }).then((json) => {
         // console.log('\n---> json <---\n', json, '\n');
         
         this.setState({
            markets: json && json.docs ? json.docs.sort(this.compare) : [],
            selectAllValue: json && json.docs ? json.docs.map(market => market._id) : []
         });
      });
   }

   isEquivilent(arr1, arr2) {
      if (arr2 && arr1 && Array.isArray(arr2) && Array.isArray(arr1)) {
         if (Object.keys(arr2).length === Object.keys(arr1).length) {
            return arr2.reduce((result, actual) => {
               return arr1.includes(actual) && result
            }, true)
         }
         return false
      }
      return false
   }

   toggleSelectAll() {
      let areEqual = false;
      areEqual = this.isEquivilent(this.state.selectAllValue, this.props.actualValue);
      if (areEqual) {
         this.props.onChange(this.props.target, [])
      } else {
         this.props.onChange(this.props.target, this.state.selectAllValue)
      }
   }


   compare(a, b) {
      if (a.prettyName < b.prettyName) return -1;
      if (a.prettyName > b.prettyName) return 1;
      return 0
   }

   render() {
      // console.log("this.state ===> ", this.state)
      return (
         <div className="checkbox-group">

            <div className="checkbox-group-header">

               <span className="label">{this.props.label}</span>

               <label htmlFor="selectAllMarkets" className="checkbox-wrapper">
                  <input type="checkbox" id="selectAllMarkets" checked={this.isEquivilent(this.props.actualValue, this.state.selectAllValue)} onChange={this.toggleSelectAll} />
                  <span/>
                  <p className="selectAll">Select All</p>
               </label>

            </div>

            <div className="checkbox-group-body">
               <Checkboxes onChange={this.marketSelect} checkboxDepth={2} value={this.props.value}>
                  {this.state.markets.map(function(market, i) {
                     return (
                        <label htmlFor={market.name} className="checkbox-wrapper" key={i}>
                           <Checkbox id={market.name} value={market._id} />
                           <span/>
                           {market.prettyName}
                        </label>
                     )
                  })}
               </Checkboxes>
            </div>

         </div>

      )
   }

}
export default CheckboxMarkets;
