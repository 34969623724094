import React from 'react';
import _displayConfiguration from "../../../json/displayConfiguration.js";
import ProgramsListItem from '../../../components/mains/ProgramsListItem';
import CheckboxGeneric from "../../elements/CheckboxGeneric/CheckboxGeneric";
import {toast} from "react-toastify";
import RequestBulkDelete from "../../modals/RequestBulkDelete/RequestBulkDelete";
import ReactModal from "react-modal";

import './ProgramsList.css';

class ProgramsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeModal: null,
            selectedPrograms: [],
            forceSelect: null
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    _selectChangeForProgramId = (id, selected) => {
        this.setState({forceSelect: null});
        const selectedPrograms = this.state.selectedPrograms;
        const idx = selectedPrograms.indexOf(id);
        if (!selected && idx > -1) {
            selectedPrograms.splice(idx, 1);
            this.setState({selectedPrograms});
            return;
        }
        if (selected && idx < 0) {
            selectedPrograms.push(id);
            this.setState({selectedPrograms});
        }
    };
    openModal(modalContent) {
        this.setState({activeModal: modalContent});
    };

    closeModal() {
        this.setState({activeModal: null});
    };
    handleDelete() {
        const token = localStorage.getItem('accessToken');
        if (!token) return;
        const FISCAL_YEAR_PLAN_ID = JSON.parse(localStorage.getItem('fiscalYearPlan'))._id;

        console.log(this.props);

        fetch(process.env.REACT_APP_API_URL + `programs/bulk`, {
            method: 'delete',
            headers: {
                'Authorization': token,
                'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({
                programs:this.state.selectedPrograms
            })
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(Promise.reject.bind(Promise));
            }
        }).then((json) => {
            this.props.updatePrograms();
            this.setState({activeModal: null});
            toast("Program(s) Deleted Successfully", {className: "notification success"});
        }).catch((err) => {
            console.log("err ===> ", err);
            this.setState({activeModal: null});
            toast(err.message, {className: "notification error"});
        });
    };

    render() {
        const data = this.props.data;
        const activeSort = this.props.activeSort;
        const fiscalYearPlan = JSON.parse(localStorage.getItem('fiscalYearPlan'));
        const productGroup = fiscalYearPlan.productGroupId.name;
        let directionDown = true;
        if (activeSort.slice(0, 1) === "-") {
            directionDown = false
        }
        return <div className="programs">
            <div className="programs-header">
                <span className="programs-header-select"><CheckboxGeneric
                    isChecked={this.state.selectedPrograms.length === data.length && data.length > 0}
                    onChange={(e) => this.setState({forceSelect: e})}/></span>
                <h4 className={activeSort.includes("prettyName") ? (directionDown ? "is-active down program-header-name" : "is-active program-header-name") : "program-header-name"}
                    onClick={() => {
                        this.props.onUpdate("prettyName");
                    }}>Program Name <div className="caret"/></h4>
                <span
                    className={activeSort.includes("marketId") ? (directionDown ? "is-active down program-header-market" : "is-active program-header-market") : "program-header-market"}
                    onClick={() => {
                        this.props.onUpdate("marketId");
                    }}>Market <div className="caret"/></span>
                {_displayConfiguration[productGroup].showDirectors &&
                <span className="program-header-director">Director</span>}
                <span
                    className={activeSort.includes("createdAt") ? (directionDown ? "is-active down program-header-date" : "is-active program-header-date") : "program-header-date"}
                    onClick={() => {
                        this.props.onUpdate("createdAt");
                    }}>Date Created <div className="caret"/></span>
                <span className="program-header-edit">Edit</span>
                <span
                    className={this.state.selectedPrograms.length > 0 ? "program-header-bulk-delete" : "program-header-delete"}
                    onClick={() => {
                        if (this.state.selectedPrograms.length < 1) return;
                        this.openModal(<RequestBulkDelete count={this.state.selectedPrograms.length} handleCancel={this.closeModal} handleDelete={this.handleDelete}/>);
                    }}>Delete{this.state.selectedPrograms.length > 0 ? " (" + this.state.selectedPrograms.length.toString() + ")" : ""}</span>
            </div>

            <div className={this.state.isLoading ? "programs-body is-loading" : "programs-body"}>
                {data.length > 0 ? data.map((program, i) => {
                    return <ProgramsListItem key={i} data={program} onUpdate={this.props.updatePrograms}
                                             isSelected={false} forceSelect={this.state.forceSelect}
                                             onSelectChange={this._selectChangeForProgramId}/>;
                }) : <div className="list-fallback">No results found.</div>}
            </div>
            <ReactModal
                isOpen={!!this.state.activeModal}
                className="react-modal-content"
                overlayClassName="react-modal-overlay"
                shouldCloseOnOverlayClick={true}
                onRequestClose={this.closeModal}>
                {this.state.activeModal}
            </ReactModal>
        </div>;
    }
}

export default ProgramsList;
