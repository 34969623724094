import React from 'react';
import PlanningListItem from '../../../components/mains/PlanningListItem';
import './PlanningList.css';

class PlanningList extends React.Component {
   render() {
      return (
         <div>
            {this.props.data.length > 0 ? (
               this.props.data.map((plan, i) => {
                  return <PlanningListItem key={i} id={plan._id} handleRefresh={this.props.handleRefresh}
                                           handleTotalsRefresh={this.props.handleTotalsRefresh} program={plan}/>
               })
            ):(
               <div className="list-fallback-planning">No results found.</div>
            )}
         </div>
      )
   }
}

export default PlanningList;
