import React from 'react';

import './Sidebar.css';

class Sidebar extends React.Component {
    render() {
        // const fiscalYearPlans = JSON.parse(localStorage.getItem('fiscalYearPlans'));
        // if (window.location.pathname.match(/top-down/)) {
        //     return null;
        // }
        return (
            <section className="sidebar">
                <div className='content'>
                    {/*Todo: redirect to environment-specific launchpad.*/}
                    <a href="https://launchpad.mktg.com">
                        <header className="sidebar-header">
                            <h1 className="logo">MKTG | Diageo</h1>
                        </header>
                    </a>

                    <div className="sidebar-inner">
                        {this.props.children}
                    </div>
                </div>

            </section>
        )
    }
}

export default Sidebar;
