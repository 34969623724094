import { AuthenticationContext, adalFetch } from 'react-adal';

export const adalConfig = {
   tenant: process.env.REACT_APP_API_TENANT,
   clientId: process.env.REACT_APP_API_CLIENT_ID,
   cacheLocation: process.env.REACT_APP_API_CACHE_LOCATION,
   postLogoutRedirectUri: process.env.REACT_APP_API_POST_LOGOUT_REDIRECT_URI,
   redirectUri: process.env.REACT_APP_API_LOGIN_REDIRECT_URI
};

export const authContext = new AuthenticationContext(adalConfig);
// console.log("authContext, adalConfig, fetch, url, options ===> ", {authContext, adalConfig})

export const adalApiFetch = (fetch, url, options) => {
   return adalFetch(authContext, null, fetch, url, options);
}
