import React, {Component} from 'react';
import './CheckboxGeneric.css';


class CheckboxGeneric extends Component {
    state = {
        checked: this.props.isChecked,
    };

    componentWillReceiveProps(nextProps) {
        if (this.state.checked !== nextProps.isChecked) {
            this.props.onChange(nextProps.isChecked);
            this.setState({checked: nextProps.isChecked});
        }
    }

    _change = (e) => {
        this.props.onChange(!this.state.checked);
        this.setState({checked: !this.state.checked});
    };

    render() {
        return (
            <label className="container">
                <input type="checkbox" onChange={this._change} value={this.state.checked} checked={this.state.checked}/>
                <span className="checkmark"/>
            </label>

        )
    }

}

CheckboxGeneric.defaultProps = {
    isChecked: false,
    onChange: (e) => {
    }
};

export default CheckboxGeneric;
