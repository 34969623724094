import React, { Component } from 'react';
import {Checkbox, CheckboxGroup as Checkboxes} from 'react-checkbox-group';

import './CheckboxDepartments.css';


class CheckboxDepartments extends Component {
   constructor(props) {
      super(props);

      this.state = {
         departments: [],
      };

      this.departmentSelect = this.departmentSelect.bind(this);
      this.isEquivalent = this.isEquivalent.bind(this);
      this.toggleSelectAll = this.toggleSelectAll.bind(this);
   }

   departmentSelect(selectedDepartments) {
      this.props.onChange(this.props.target, selectedDepartments);
   }

   componentDidMount() {
      const token = localStorage.getItem('accessToken');
      if (!token) return;

      fetch(process.env.REACT_APP_API_URL + 'departments?sort=order', {
         method: 'get',
         headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
         }
      }).then((resp) => {
         return resp.json()
      }).then((respJson) => {
         
         this.setState({
            departments: respJson && respJson.docs ? respJson.docs : [],
            selectAllValue: respJson && respJson.docs ? respJson.docs.map(department => department._id) : []
         });
      });
   }

   isEquivalent(arr1, arr2) {
      if (arr2 && arr1 && Array.isArray(arr2) && Array.isArray(arr1)) {
         if (Object.keys(arr2).length === Object.keys(arr1).length) {
            return arr2.reduce((result, actual) => {
               return arr1.includes(actual) && result
            }, true)
         }
         return false
      }
      return false
   }

   toggleSelectAll() {
      let areEqual = this.isEquivalent(this.state.selectAllValue, this.props.actualValue);
      if (areEqual) {
         this.props.onChange(this.props.target, []);
      } else {
         this.props.onChange(this.props.target, this.state.selectAllValue);
      }
   }

   render() {
      return (
         <div className="checkbox-group">

            <div className="checkbox-group-header">

               <span className="label">{this.props.label}</span>

               <label htmlFor="selectAllDepartments" className="checkbox-wrapper">
                  <input type="checkbox" id="selectAllDepartments" checked={this.isEquivalent(this.props.actualValue, this.state.selectAllValue)} onChange={this.toggleSelectAll} />
                  <span/>
                  <p className="selectAll">Select All</p>
               </label>

            </div>

            <div className="checkbox-group-body">
               <Checkboxes onChange={this.departmentSelect} checkboxDepth={2} value={this.props.value}>
                  {this.state.departments.map(function(department, i) {
                     return (
                        <label htmlFor={department.prettyName} className="checkbox-wrapper" key={i}>
                           <Checkbox id={department.prettyName} value={department._id} />
                           <span/>
                           {department.prettyName}
                        </label>
                     )
                  })}
               </Checkboxes>
            </div>

         </div>

      )
   }

}
export default CheckboxDepartments;
