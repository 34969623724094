import React from 'react';
import './Drawer.css';

class Drawer extends React.Component {
   render() {
      return (
         <section className="drawer">
            {this.props.children}
         </section>
      )
   }
}

export default Drawer;
