// export const handleQueryString = () => {
//    let qs = "";
//    let filters = localStorage.getItem("filters");
//    if (filters) {
//       filters = JSON.parse(filters);
//       let brands = filters.brands && filters.brands.length > 0 ? `&brandId=${filters.brands.join(' ')}` : "";
//       let channels = filters.channels && filters.channels.length > 0 ? `&channelId=${filters.channels.join(' ')}` : "";
//       let divisions = filters.divisions && filters.divisions.length > 0 ? `&divisionId=${filters.divisions.join(' ')}` : "";
//       let markets = filters.markets && filters.markets.length > 0 ? `&marketId=${filters.markets.join(' ')}` : "";
//       let programs = filters.programs && filters.programs.length > 0 ? `&campaignId=${filters.programs.join(' ')}` : "";
//       let directors = filters.directors && filters.directors.length > 0 ? `&directorId=${generateGroupedDirectorString(filters.directors, filters['joint-directors'])}` : "";
//       qs = `${brands}${channels}${divisions}${markets}${programs}${directors}`
//    }
//    return qs || ""
// };

export const generateGroupedDirectorString = (directors, groups) =>{
   let allDirectors = [];
   directors.forEach((director)=>{
      allDirectors.push(director);
      if (groups.hasOwnProperty(director)){
         allDirectors = allDirectors.concat(groups[director]);
      }
   });
   return allDirectors.join(' ');
};

export const handleQueryDepartmentString = () => {
   let department = JSON.parse(localStorage.getItem("department"));
   if(department) {
      return `&departmentId=${department.value}`;
   }
   return "";
};

export const addCommas = (num) => {
   return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


