import React, {Component} from 'react';
import {authContext} from '../../../adalConfig';
import {toast} from 'react-toastify';
import update from 'immutability-helper';
import ReactTooltip from 'react-tooltip';

import PlanHeader from '../../../components/elements/PlanHeader';
import PlanGrid from '../../../components/elements/PlanGrid';
import {addCommas} from "../../../utils";

import './PlanningListItem.css';


class ProgramsListItem extends Component {
    constructor(props) {
        super(props);

        let program = this.props.program;
        const monthItems = this.fashionMonthItems(program);
        // sort months to start with july (q1 for diageo)
        const sortedMonths = monthItems.slice(6).concat(monthItems.slice(0, 6));

        this.state = {
            monthItems: sortedMonths,
            marketName: program.marketId.prettyName,
            programName: program.prettyName,
            directorName: program.directorId ? program.directorId.prettyName : "null",
            lastSaved: program.updatedAt,
            eventType: program.channelId.name,
            status: program.status.replace("Not", "Not ")
        };

        this.handleUpdate = this.handleUpdate.bind(this);
        this.recalculateCount = this.recalculateCount.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.calculatePosTotal = this.calculatePosTotal.bind(this);
        this.handleCostUpdate = this.handleCostUpdate.bind(this);
        this.putProgram = this.putProgram.bind(this);
    }

    handleUpdate(index, departmentType, value) {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null
        }

        if (typeof value === 'string' && value.includes('-')) return; // prevent negative counts

        const newCount = update(this.state.monthItems, {
            [index]: {
                count: {$merge: {[departmentType]: Number(value)}}
            }
        });

        this.setState({
            monthItems: newCount
        });

        this.timeout = setTimeout(function () {
            this.putProgram();
            this.timeout = null;
        }.bind(this), 3000)
    }

    handleCostUpdate(index, value) {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null
        }

        let newMonthItems = Object.assign([], this.state.monthItems);

        value = value.replace(/,/g, '');
        if (typeof value === "string" && value.match(/[^0-9]+/)) return;

        newMonthItems[index].eventCostTotal = value;
        if (newMonthItems[index].count === 0) { // eslint-disable-line
            newMonthItems[index].count = 1;
            newMonthItems[index].kpiTotal = newMonthItems[index].kpi;
        }

        this.setState({monthItems: newMonthItems});

        this.timeout = setTimeout(function () {
            this.putProgram();
            this.timeout = null;
        }.bind(this), 3000);
    }

    recalculateCount(index, value, basis) {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null
        }
        // if (typeof value === "string" && value.includes('-')) return; // prevent negative counts
        value = value.replace(/,/g, '');
        if (typeof value === "string" && value.match(/[^0-9]+/)) return;

        let calculatedCount;
        let newMonthItems = Object.assign([], this.state.monthItems);
        const kpiValue = +this.state.monthItems[index].kpi;
        const eventCost = +this.state.monthItems[index].eventCost;

        if (basis && basis === "count") {
            calculatedCount = +value
            if (this.state.eventType !== "special") {
                newMonthItems[index].eventCostTotal = Number((eventCost) * calculatedCount);
            }
            newMonthItems[index].kpiTotal = Number(calculatedCount * kpiValue);
        }

        if (basis && basis === "kpi") {
            calculatedCount = Math.floor(+value / kpiValue);
            newMonthItems[index].kpiTotal = Number(value);
            if (this.state.eventType !== "special") {
                newMonthItems[index].eventCostTotal = Number((eventCost) * calculatedCount);
            }
        }

        if (basis && basis === "cost") {
            calculatedCount = Math.floor(+value / (eventCost));
            newMonthItems[index].eventCostTotal = Number(value);
            newMonthItems[index].kpiTotal = Number(calculatedCount * kpiValue);
        }

        // set new count
        newMonthItems[index].count = Number(calculatedCount);

        this.setState({monthItems: newMonthItems});
        this.timeout = setTimeout(function () {
            this.putProgram();
            this.timeout = null;
        }.bind(this), 3000)
    }

    toggleCollapse(e) {
        let tile = document.body.querySelector(`.tile[data-id="${this.props.id}"]`);
        if (tile.classList.contains("is-collapsed")) {
            tile.classList.remove("is-collapsed")
        } else {
            tile.classList.add("is-collapsed")
        }
    }

    putProgram() {
        const token = localStorage.getItem('accessToken');
        if (!token) return;

        const fiscalYearPlan = JSON.parse(localStorage.getItem('fiscalYearPlan'));

        // sort months back to january-dec for post
        const body = Object.assign({}, this.state);
        body.monthItems = body.monthItems.slice(6).concat(this.state.monthItems.slice(0, 6)); //Sorted Months
        const FISCAL_YEAR_PLAN_ID = fiscalYearPlan._id;

        fetch(process.env.REACT_APP_API_URL + 'programs/' + this.props.id, {
            method: 'put',
            headers: {
                'Authorization': token,
                'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then((response) => {
            if (response.status === 498) {
                authContext._renewIdToken((err, token) => {
                    this.putProgram()
                })
            } else if (response.ok) {
                return response.json()
            } else {
                return response.json().then(Promise.reject.bind(Promise));
            }
        }).then((json) => {
            // sort months to start with july (q1 for diageo)
            const sortedMonths = json.monthItems.slice(6).concat(json.monthItems.slice(0, 6));

            this.setState({
                monthItems: sortedMonths.map((month, i) => {
                    month.kpiTotal = this.state.monthItems[i].kpiTotal;
                    month.eventCostTotal = this.state.monthItems[i].eventCostTotal;
                    return month;
                }),
                programName: json.prettyName,
                lastSaved: json.updatedAt,
                status: json.status.replace("Not", "Not ")
            }, this.props.handleTotalsRefresh());
        }).catch((error) => {
            toast("Error Updating Plan", {className: "notification error"});
            console.log(error);
        });
    }

    fashionMonthItems(json) {
        return json.monthItems.map(month => {
            month.kpiTotal = +month.count * +month.kpi;
            month.eventCostTotal = +month.count * (month.eventCost ? +month.eventCost : 0);

            if (json.channelId && json.channelId.name === 'special') {
                month.eventCostTotal = +month.count * +month.eventCost;
            }

            return month
        })
    }

    calculatePosTotal(department) {
        department = department === "national accounts" ? 'national' : department;

        let result = 0;
        if (this.state.monthItems && Array.isArray(this.state.monthItems)) {
            result = this.state.monthItems.reduce((sum, month) => {
                if (month.posKitId) {
                    return sum + month.count[department] * month.posKitId.total
                }
                return sum
            }, 0)
        } else {
            return 0
        }
        return addCommas(parseFloat(result).toFixed(2))
    }

    render() {
        if(this.state.monthItems) {
            let hasEventCost = this.state.monthItems[0] && this.state.monthItems[0].eventCost;

            return (
                <div className="tile is-collapsed" data-id={this.props.id}>

                    <PlanHeader
                        data={this.state}
                        id={this.props.id}
                        toggleCollapse={(e) => {
                            this.toggleCollapse(e)
                        }}
                    />

                    <div className='tabs-nav'>
                        {this.state.eventType !== "special" && hasEventCost > 0 &&
                        <span className="event-cost">
                            <span>Single Event Sampling Cost: ${Math.round(this.state.monthItems[0].eventCost)}</span>
                            <div data-for={this.props.id + "eventCost"} data-place="bottom"
                                 data-tip="Labor + Sampling Spend + Mileage + Shipping + Training + Misc"
                                 className={"tooltip-button is-active"}/>
                        </span>
                        }
                    </div>
                    <ReactTooltip id={this.props.id + "eventCost"} place="bottom" type="dark" effect="solid"
                                  className="tooltip"/>

                    <div className="tile-body">
                        <PlanGrid specialEvent={this.state.eventType === "special"}
                                  data={this.state.monthItems}
                                  editable={true}
                                  onUpdate={this.handleUpdate}
                                  onCostUpdate={this.handleCostUpdate}
                                  onRecalculate={this.recalculateCount}/>
                    </div>
                </div>
            )
        } else {return null;}
    }
}

export default ProgramsListItem;
