import React, { Component } from 'react';
import {Checkbox, CheckboxGroup as Checkboxes} from 'react-checkbox-group';
import './CheckboxDirectors.css';

class CheckboxDirectors extends Component {
   constructor(props) {
      super(props);

      this.state = {
         directors: [],
      };

      this.directorSelect = this.directorSelect.bind(this);
      this.isEquivilent = this.isEquivilent.bind(this);
      this.toggleSelectAll = this.toggleSelectAll.bind(this);
   }

   directorSelect(selectedDirectors) {
      this.props.onChange(this.props.target, selectedDirectors);
   }

   componentDidMount() {
      const token = localStorage.getItem('accessToken');
      if (!token) return;
      const { _id: FISCAL_YEAR_PLAN_ID, productGroupId: { _id: productGroupId } } = JSON.parse(localStorage.getItem('fiscalYearPlan'));
      fetch(process.env.REACT_APP_API_URL + 'directors?productGroupId=' + productGroupId , {
         method: 'get',
         headers: {
            'Authorization': token,
            'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
            'Content-Type': 'application/json'
         }
      }).then((response) => {
         return response.json()
      }).then((json) => {
         // console.log('\n---> json <---\n', json, '\n');

         this.setState({
            directors: json && json.docs ? json.docs.sort(this.compare) : [],
            selectAllValue: json && json.docs ? json.docs.map(director => director._id) : []
         });
      });
   }

   isEquivilent(arr1, arr2) {
      if (arr2 && arr1 && Array.isArray(arr2) && Array.isArray(arr1)) {
         if (Object.keys(arr2).length === Object.keys(arr1).length) {
            return arr2.reduce((result, actual) => {
               return arr1.includes(actual) && result
            }, true)
         }
         return false
      }
      return false
   }

   toggleSelectAll() {
      let areEqual = false;
      let selectAllValue = this.state.directors
          .filter((d)=>this.props.matchMarkets.indexOf(d.marketId) >= 0)
          .map((d)=>d._id+'|'+d.marketId);
      this.setState({selectAllValue});
      areEqual = this.isEquivilent(this.state.selectAllValue, this.props.actualValue)
      if (areEqual) {
         this.props.onChange(this.props.target, [])
      } else {
         this.props.onChange(this.props.target, this.state.selectAllValue)
      }
   }


   compare(a, b) {
      if (a.prettyName < b.prettyName) return -1;
      if (a.prettyName > b.prettyName) return 1;
      return 0
   }

   render() {
       const matchMarkets = this.props.matchMarkets;
      return (
         <div className="checkbox-group">

            <div className="checkbox-group-header">

               <span className="label">{this.props.label}</span>

               <label htmlFor="selectAllDirectors" className="checkbox-wrapper">
                  <input type="checkbox" id="selectAllDirectors" checked={this.isEquivilent(this.props.actualValue, this.state.selectAllValue)} onChange={this.toggleSelectAll} />
                  <span/>
                  <p className="selectAll">Select All</p>
               </label>

            </div>

            <div className="checkbox-group-body">
               <Checkboxes onChange={this.directorSelect} checkboxDepth={2} value={this.props.value}>
                  {this.state.directors
                      .map(function(director, i) {
                          if (matchMarkets.indexOf(director.marketId) < 0) return;
                     return (
                        <label htmlFor={director._id} className="checkbox-wrapper wide-column" key={i}>
                           <Checkbox id={director._id} value={director._id+'|'+director.marketId} />
                           <span/>
                           {director.prettyName}
                        </label>
                     )
                  })}
               </Checkboxes>
            </div>

         </div>

      )
   }

}
export default CheckboxDirectors;
