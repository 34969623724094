import React from 'react';
import { authContext } from '../../../adalConfig';
import './NotAllowed.css';

class NotAllowed extends React.Component {
   render() {
      return (
         <div className="not-allowed">
            <h3>You don't have permission to view this page.</h3>
            <p>
               If this is a mistake, please try <a onClick={() => {authContext.logOut()}}>logging out</a> and back in. If the
               issue still persists, please contact your system administrator.
            </p>
         </div>
      )
   }
}

export default NotAllowed;
