import React from 'react';
import './RadioTotals.css';

class RadioTotals extends React.Component {
    constructor(props){
     super(props);
     this.state = {
        currentOption: this.props.currentOption
     }
     this.handleChange = this.handleChange.bind(this)
   }

   componentDidMount() {
      this.setState({ currentOption: this.props.currentOption })
   }

   handleChange(option) {
      this.setState({ currentOption: option })
      this.props.handleChange(option)
   }

   render() {
      return (
         <div className="form">
            <div className="form-header">
               <div>Select {this.props.dataType} to Filter</div>
               <div className="buttons">
                  <button className="apply" onClick={this.props.handleCancel}>Apply</button>
               </div>
            </div>
            <div className="form-body">
               <div className="radio-group-body">
                  {this.props.data.map(function(option, i) {
                     return (
                        <label className="radio-wrapper radio-block" key={i}>
                           <input type="radio" value={option.value} checked={option.value === this.state.currentOption}  onChange={() => this.handleChange(option.value)} />
                           <span><span></span></span>
                           {option.name}
                        </label>
                     )
                  }, this)}
               </div>
            </div>
         </div>
      )
   }
}

export default RadioTotals;
