import React from 'react';
import {withRouter} from 'react-router-dom';
import ReactModal from 'react-modal';
import {toast} from 'react-toastify';
import moment from 'moment';
import _displayConfiguration from "../../../json/displayConfiguration.js";
import CheckboxGeneric from '../../../components/elements/CheckboxGeneric';
import RequestDelete from '../../../components/modals/RequestDelete';

import './ProgramsListItem.css';

ReactModal.setAppElement('#root');

class ProgramsListItem extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            activeModal: null,
            isSelected: this.props.isSelected,
            forceSelect: this.props.forceSelect
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }


    openModal(modalContent) {
        this.setState({activeModal: modalContent});
    }

    closeModal() {
        this.setState({activeModal: null});
    }

    handleDelete() {
        const token = localStorage.getItem('accessToken');
        if (!token) return;
        const FISCAL_YEAR_PLAN_ID = JSON.parse(localStorage.getItem('fiscalYearPlan'))._id;

        let {data} = this.props
        fetch(process.env.REACT_APP_API_URL + `programs/${data ? data._id : ""}`, {
            method: 'delete',
            headers: {
                'Authorization': token,
                'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(Promise.reject.bind(Promise));
            }
        }).then((json) => {
            this.props.onUpdate();
            this.setState({activeModal: null});
            toast("program deleted successfully", {className: "notification success"});
        }).catch((err) => {
            console.log("err ===> ", err);
            this.setState({activeModal: null});
            toast(err.message, {className: "notification error"});
            console.log('catch');
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({forceSelect: nextProps.forceSelect});
    }

    _programSelected = (id, selected) =>{
        this.setState({isSelected:selected});
        this.props.onSelectChange(id, selected);
    };

    render() {
        const {data} = this.props;
        let date = moment(data.createdAt).format('MM.DD.YY');
        const fiscalYearPlan = JSON.parse(localStorage.getItem('fiscalYearPlan'));
        const productGroup = fiscalYearPlan.productGroupId.name;
        return (
            <div className="program">
                <span className="program-select"><CheckboxGeneric isChecked={this.state.forceSelect !== null ? this.state.forceSelect : this.state.isSelected}
                                                                  onChange={(s)=>this._programSelected(data._id, s)}/></span>
                <h4 className="program-name">{data.prettyName}</h4>
                <span className="program-market">{data.marketId ? data.marketId.prettyName : null}</span>
                {_displayConfiguration[productGroup].showDirectors &&
                <span className="program-director">{data.directorId ? data.directorId.prettyName : null}</span>
                }
                <span className="program-date">{date}</span>
                <span className="program-edit">
               <a className="edit" onClick={() => {
                   this.props.history.push('?editProgram=' + data._id)
               }}>edit</a>
            </span>
                <span className="program-delete">
               <a className="delete" onClick={() => {
                   this.openModal(<RequestDelete handleCancel={this.closeModal} handleDelete={this.handleDelete}/>)
               }}>delete</a>
            </span>
                <ReactModal
                    isOpen={this.state.activeModal ? true : false}
                    className="react-modal-content"
                    overlayClassName="react-modal-overlay"
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.closeModal}>
                    {this.state.activeModal}
                </ReactModal>
            </div>
        )
    }
}

ProgramsListItem.defaultProps = {
    isSelected: false,
    onSelectChange: (e) => {
    }
};

export default withRouter(ProgramsListItem);
