import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { authContext } from '../../../adalConfig';
import { toast } from 'react-toastify';

import Form from '../../../components/elements/Form';
import Input from '../../../components/elements/Input';

import './FinancialYear.css';
import CheckboxGeneric from "../../elements/CheckboxGeneric/CheckboxGeneric";
import jwt from "jsonwebtoken";
import RoleAwareComponent from "../../elements/RoleAwareComponent/RoleAwareComponent";


class FinancialYear extends React.Component {
    productGroupId;
    constructor(props) {
        super(props);

        this.state = {
            planName: '',
            newPlanName: '',
            newPlanPeriod: '',
            currentFinancialYears: [],
            currentFinancialYear: {},
            enabledFilters: {
                divisions: true,
                markets: true,
                channels: true,
                brands: true,
                programs: true,
                managers: false,
                status: true
            },
            selectCurrentFiscalYear:null,
            newPlanStartsOn: 7,
            isDefault: false,
            enabledPeriods:{
                q1: true,
                q2: true,
                q3: true,
                q4: true
            },

            //////UNUSED
            firstName: "",
            lastName: "",
            email: "",
            adObjectId: "",
            roleId: "",
            brands: [],
            channels: [],
            clients: [],
            directors: [],
            divisions: [],
            departments: [],
            markets: [],
            productGroups: [],
            data: {},
            selectAll: {
                brands: false,
                channels: false,
                clients: false,
                directors: false,
                divisions: true,
                departments: false,
                markets: false,
                productGroups: false,
            },

            _activeTab: 0,
            _isLoading: false
        }

        this.submitNewPlan = this.submitNewPlan.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.switchPlan = this.switchPlan.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.loadFiscalYearPlans = this.loadFiscalYearPlans.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    componentDidMount() {
        let currentFiscalYearPlan = JSON.parse(localStorage.getItem('fiscalYearPlan'));
        let otherFiscalYearPlans = JSON.parse(localStorage.getItem('fiscalYearPlans')).filter((e)=>e.productGroupId._id === currentFiscalYearPlan.productGroupId._id && e._id !== currentFiscalYearPlan._id);
        this.setState({currentFinancialYears:otherFiscalYearPlans, currentFinancialYear: currentFiscalYearPlan, selectCurrentFiscalYear: currentFiscalYearPlan._id});
        this.loadFiscalYearPlans();
    }

    loadFiscalYearPlans(){
        const token = localStorage.getItem('accessToken');
        if (!token) return;
        fetch(process.env.REACT_APP_API_URL + `roles/user` , {
            method: 'get',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return response.json().then(Promise.reject.bind(Promise));
            }
        }).then((json) => {
            let { fiscalYearPlans, user } = json;

            // set userToken
            let signedUser = jwt.sign(user, process.env.REACT_APP_ROLE_SECRET);

            localStorage.setItem('userToken', signedUser);
            localStorage.setItem('fiscalYearPlans', JSON.stringify(fiscalYearPlans));

            let currentFiscalYearPlan = JSON.parse(localStorage.getItem('fiscalYearPlan'));
            let otherFiscalYearPlans = JSON.parse(localStorage.getItem('fiscalYearPlans')).filter((e)=>e.productGroupId._id === currentFiscalYearPlan.productGroupId._id && e._id !== currentFiscalYearPlan._id);
            this.setState({currentFinancialYears:otherFiscalYearPlans, currentFinancialYear: currentFiscalYearPlan});

        }).catch((err) => {
            this.setState({ isLoading:false });
        });
    }

    switchPlan(){
            let allPlans = JSON.parse(localStorage.getItem('fiscalYearPlans'));
            let selectedPlan = allPlans.filter((f)=>f._id===this.state.selectCurrentFiscalYear)[0];
            localStorage.setItem('fiscalYearPlan', JSON.stringify(selectedPlan));
            this.props.handleRefresh();
            this.props.handleCancel();

    }
    submitNewPlan(){
        if (this.state.newPlanName === '' || this.state.newPlanPeriod === ''){
            toast('Please enter a complete plan name and period.', { className: "notification error"});
            return;
        }

        const token = localStorage.getItem('accessToken');
        const currentFiscalYearPlan = JSON.parse(localStorage.getItem('fiscalYearPlan'));
        if (!token) return;

        this.setState({ _isLoading: true });

        let planningPeriodCount=0;
        for (const key of Object.keys(this.state.enabledPeriods)) {
           planningPeriodCount += (this.state.enabledPeriods[key] ? 1 : 0);
        }
        let body = {
            fiscalYear: this.state.newPlanName,
            currentPeriod: this.state.newPlanPeriod,
            startsOn: this.state.newPlanStartsOn,
            productGroupId: currentFiscalYearPlan.productGroupId._id,
            enabledPeriods: this.state.enabledPeriods,
            planningPeriods: planningPeriodCount,
            active: true,
            enabledFilters: this.state.enabledFilters,
            isDefault: this.state.isDefault
        };
        fetch(process.env.REACT_APP_API_URL + 'fiscal-year-plan', {
            method: 'post',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then((response) => {
            if (response.status === 498) {
                authContext._renewIdToken((err, token) => {
                    this.submitNewPlan()
                })
            } else if (response.ok) {
                return response.json()
            } else {
                return response.json().then(Promise.reject.bind(Promise));
            }
        }).then((json) => {
            if (!json) return;
            toast("Fiscal plan '"+body.fiscalYear+"' created!", { className: "notification success"});
            this.setState({ _isLoading: false });
            this.props.handleRefresh();
            this.props.handleCancel();
        }).catch((error) => {
            toast(error.message, { className: "notification error"});
            this.setState({ _isLoading: false })
        });
    }

    handleCheckboxChange(section, target, value) {
        if (typeof section === 'string') {
            let updateObj = this.state[section];
            updateObj[target] = value;

            this.setState({
                [section]:updateObj
            });
            return;
        }
        this.setState({
            [target]:value
        });

    }

    handleChange(target, value) {
        this.setState({
            [target]: value
        });
    }

    handleSelect(e){
        this.setState({
           [e.target.id]:e.target.value
        });
    }

    render() {
        return (
            <Form className="form user-form">

                <Tabs selectedTabClassName="is-active" selectedIndex={this.state._activeTab} onSelect={_activeTab => this.setState({_activeTab: _activeTab})}>

                    <div className="form-header">

                        <TabList className="tab-links">
                            <Tab className="tab">Switch Fiscal Year</Tab>
                            <RoleAwareComponent access={["admin"]}>
                                <Tab className="tab" disabled={this.state._isLoading}>Create Fiscal Year</Tab>
                            </RoleAwareComponent>
                        </TabList>

                    </div>

                    <div className="form-body">
                        <TabPanel>

                            <div>

                                <label className="label" >Fiscal Year</label>

                                <div className="custom-select">
                                    <select  onChange={this.handleSelect}  id="selectCurrentFiscalYear">
                                        <option default value={this.state.currentFinancialYear._id}>{this.state.currentFinancialYear.fiscalYear}</option>
                                        {this.state.currentFinancialYears.map(function(fy, i) {
                                            return (
                                                <option value={fy._id} key={i}>{fy.fiscalYear}</option>
                                            )
                                        })}

                                    </select>
                                </div>

                            </div>

                            <div className="form-footer">
                                <button className="button submit" disabled={this.state._isLoading} onClick={this.switchPlan}>Switch Fiscal Year</button>
                            </div>


                        </TabPanel>
                        <TabPanel>

                            <div className="form-section">
                                <div className="input-wrapper half">
                                    <Input label="Plan Name" id="newPlanName" value={this.state.newPlanName} type="text" target="newPlanName" onChange={this.handleChange} required />
                                </div>
                                <div className="input-wrapper half">
                                    <Input label="Current Fiscal Planning Period" id="newPlanPeriod" value={this.state.newPlanPeriod} type="text" target="newPlanPeriod" onChange={this.handleChange} required />
                                </div>
                                <div className="input-wrapper half">
                                    <label className="label" >Fiscal Start Month</label>
                                    <div className="custom-select">
                                    <select onChange={this.handleSelect}  id="newPlanStartsOn" value={this.state.newPlanStartsOn} >
                                        <option default value="7" key={0}>July start</option>
                                        <option value="1" key={1}>January start</option>
                                    </select>
                                    </div>
                                       </div>
                                <div className="input-wrapper half">
                                    <div className="labelled-checkbox">
                                        <CheckboxGeneric onChange={(c)=>this.handleCheckboxChange(null ,'isDefault', c)} isChecked={this.state.isDefault} />
                                        <span></span>
                                        <div className="checkbox-label">
                                        Default fiscal plan
                                        </div>
                                    </div>
                                </div>
                                <div className="input-wrapper full">
                                    <label className="label" >Enabled Filters</label>
                                    <div className="checkbox-grid">

                                    <div className="labelled-checkbox">
                                        <CheckboxGeneric onChange={(c)=>this.handleCheckboxChange('enabledFilters','divisions', c)} isChecked={this.state.enabledFilters.divisions}/>
                                        <span></span>
                                        <div className="checkbox-label">
                                            Divisions
                                        </div>
                                    </div>

                                        <div className="labelled-checkbox">
                                            <CheckboxGeneric onChange={(c)=>this.handleCheckboxChange('enabledFilters','markets', c)} isChecked={this.state.enabledFilters.markets}/>
                                            <span></span>
                                            <div className="checkbox-label">
                                                Markets
                                            </div>
                                        </div>

                                        <div className="labelled-checkbox">
                                            <CheckboxGeneric onChange={(c)=>this.handleCheckboxChange('enabledFilters','channels', c)} isChecked={this.state.enabledFilters.channels}/>
                                            <span></span>
                                            <div className="checkbox-label">
                                                Channels
                                            </div>
                                        </div>

                                        <div className="labelled-checkbox">
                                            <CheckboxGeneric onChange={(c)=>this.handleCheckboxChange('enabledFilters','brands', c)} isChecked={this.state.enabledFilters.brands}/>
                                            <span></span>
                                            <div className="checkbox-label">
                                                Brands
                                            </div>
                                        </div>

                                        <div className="labelled-checkbox">
                                            <CheckboxGeneric onChange={(c)=>this.handleCheckboxChange('enabledFilters','programs', c)} isChecked={this.state.enabledFilters.programs}/>
                                            <span></span>
                                            <div className="checkbox-label">
                                                Programs
                                            </div>
                                        </div>

                                        <div className="labelled-checkbox">
                                            <CheckboxGeneric onChange={(c)=>this.handleCheckboxChange('enabledFilters','managers', c)} isChecked={this.state.enabledFilters.managers}/>
                                            <span></span>
                                            <div className="checkbox-label">
                                                Managers
                                            </div>
                                        </div>

                                        <div className="labelled-checkbox">
                                            <CheckboxGeneric onChange={(c)=>this.handleCheckboxChange('enabledFilters', 'status', c)} isChecked={this.state.enabledFilters.status}/>
                                            <span></span>
                                            <div className="checkbox-label">
                                                Status
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="input-wrapper full">
                                    <label className="label" >Planning Periods Enabled</label>
                                    <div className="checkbox-grid">

                                        <div className="labelled-checkbox quarter">
                                            <CheckboxGeneric onChange={(c)=>this.handleCheckboxChange('enabledPeriods','q1', c)} isChecked={this.state.enabledPeriods.q1}/>
                                            <span></span>
                                            <div className="checkbox-label">
                                                Q1
                                            </div>
                                        </div>

                                        <div className="labelled-checkbox quarter">
                                            <CheckboxGeneric onChange={(c)=>this.handleCheckboxChange('enabledPeriods','q2', c)} isChecked={this.state.enabledPeriods.q2}/>
                                            <span></span>
                                            <div className="checkbox-label">
                                                Q2
                                            </div>
                                        </div>

                                        <div className="labelled-checkbox quarter">
                                            <CheckboxGeneric onChange={(c)=>this.handleCheckboxChange('enabledPeriods','q3', c)} isChecked={this.state.enabledPeriods.q3}/>
                                            <span></span>
                                            <div className="checkbox-label">
                                                Q3
                                            </div>
                                        </div>

                                        <div className="labelled-checkbox quarter">
                                            <CheckboxGeneric onChange={(c)=>this.handleCheckboxChange('enabledPeriods','q4', c)} isChecked={this.state.enabledPeriods.q4}/>
                                            <span></span>
                                            <div className="checkbox-label">
                                                Q4
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-footer">
                                <button className="button submit" disabled={this.state._isLoading} onClick={this.submitNewPlan}>Create Fiscal Year</button>
                            </div>

                        </TabPanel>

                    </div>

                </Tabs>

            </Form>)
    }
}

export default FinancialYear;
