import React from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';

import Filters from '../../../components/elements/Filters';
import RoleAwareComponent from '../../../components/elements/RoleAwareComponent';
import CampaignForm from '../../../components/drawers/CampaignForm';
import SalesforceIntegration from '../../../components/drawers/SalesforceIntegration';
import UploadProgramCsv from '../../../components/modals/UploadProgramCsv';
import UploadBudgetCsv from '../../../components/modals/UploadBudgetCsv';

import './PreWork.css';
import UploadKPICsv from "../../modals/UploadKPICsv/UploadKPICsv";

class PreWork extends React.Component {

   render() {
      return (
         <div>
            <ul className="sidebar-links">
               <li>
                  <NavLink onClick={this.props.collapseDrawer} to="/pre-work/programs" activeClassName="is-active">View Programs</NavLink>
               </li>
               <RoleAwareComponent access={["admin"]}>
                  <li>
                     <a onClick={() => this.props.expandDrawer(<CampaignForm narrow={false} handleRefresh={this.props.handleRefresh} handleCancel={this.props.handleCancel} />)}>Create New Campaign</a>
                  </li>
                  <li>
                     <a onClick={() => this.props.expandDrawer(<SalesforceIntegration narrow={false} handleRefresh={this.props.handleRefresh} handleCancel={this.props.handleCancel} />)}>Salesforce Integration</a>
                  </li>
               </RoleAwareComponent>
               {/* <li>
                  <a onClick={() => this.props.expandDrawer(<PosKitForm narrow={false} handleCancel={this.props.handleCancel} />)}>Create New POS Kit</a>
               </li> */}
               <RoleAwareComponent access={["admin"]}>
                  {/*<li className="icon-upload">*/}
                  {/*   <a onClick={() => this.props.openModal(<UploadProgramCsv onClose={this.props.closeModal} handleRefresh={this.props.handleRefresh} />)}>Upload Program CSV</a>*/}
                  {/*</li>*/}
                  <li className="icon-upload">
                     <a onClick={() => this.props.openModal(<UploadBudgetCsv onClose={this.props.closeModal} handleRefresh={this.props.handleRefresh} />)}>Upload Budget CSV</a>
                  </li>
                   <li className="icon-upload">
                       <a onClick={() => this.props.openModal(<UploadKPICsv onClose={this.props.closeModal} handleRefresh={this.props.handleRefresh} />)}>Upload KPI CSV</a>
                   </li>
               </RoleAwareComponent>
            </ul>
            <Switch>
               <Route path="/pre-work/programs"><Filters {...this.props} type="programs" /></Route>
            </Switch>

         </div>
      )
   }
}

export default PreWork;
