import React from 'react';
// import NotFound from '../../../components/views/NotFound';
import NotAllowed from '../../../components/views/NotAllowed';
import jwt from 'jsonwebtoken';

class RoleAwarePage extends React.Component {
   constructor() {
      super();

      this.state = {
         allowed: false
      }
   }

   componentDidMount() {
      const userToken = localStorage.getItem('userToken');

      if (userToken) {
         const roleAlias = jwt.verify(userToken, process.env.REACT_APP_ROLE_SECRET, function(err, decoded){
            if (err) console.log(err);
            return decoded.access;
         });

         if (roleAlias) {
            const allowed = this.props.access.includes(roleAlias);
            this.setState({ allowed: allowed });
         }
      }
   }

   render() {
      if (this.state.allowed) {
         return this.props.children;
      } else {
         return <NotAllowed />;
      }
   }
}

export default RoleAwarePage;
