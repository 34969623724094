import React, { Component } from 'react';

import {addCommas} from "../../../utils";

import './MonthItem.css';


class MonthItem extends Component {

   handleEventCost(e, index) {
      if (this.props.specialEvent) {
         this.props.onCostUpdate(index, e.target.value)
      } else {
         this.props.onRecalculate(index, e.target.value, "cost")
      }
   }

   render() {
      const data = this.props.data;
      const index = this.props.index;

      return (
         <div className={this.props.editable ? "grid-month" : "grid-month is-disabled"}>
            <span className="header">{data.prettyNameShort}</span>
            <input type="text" disabled={!this.props.editable} value={addCommas(Math.round(data.count)) || "0"} onChange={(e) => this.props.onRecalculate(index, e.target.value, "count")} />
            <input type="text" disabled={!(this.props.editable && this.props.data.kpi)} value={addCommas(Math.round(+data.kpiTotal)) || "0"} onChange={(e) => this.props.onRecalculate(index, e.target.value, "kpi")} />
            {this.props.specialEvent ? (
               <input type="text" disabled={!this.props.editable} value={addCommas(Math.round(+data.eventCostTotal)) || "0"} onChange={(e) => this.handleEventCost(e, index)} />
            ):(
               <input type="text" disabled={!(this.props.editable && this.props.data.eventCost)} value={addCommas(Math.round(+data.eventCostTotal)) || "0"} onChange={(e) => this.handleEventCost(e, index)} />
            )}
         </div>
      )
   }
}

export default MonthItem;
