import React from 'react';
import { toast } from 'react-toastify';

import Form from '../../../components/elements/Form';
import RadioTools from  '../../../components/elements/RadioTools';
import SelectCampaign from '../../../components/elements/SelectCampaign';
import SelectMarket from '../../../components/elements/SelectMarket';

import './SalesforceIntegration.css';

const QUARTERS = [
   {
      value: 'Q1',
      name: 'Q1',
   },
   {
      value: 'Q2',
      name: 'Q2',
   },
   {
      value: 'Q3',
      name: 'Q3',
   },
   {
      value: 'Q4',
      name: 'Q4',
   },
];

class SalesforceIntegration extends React.Component {
   constructor(props){
      super(props);
      this.state = {
         departments: [],
         departmentId: "",
         quarters: QUARTERS,
         quarter: "",
         campaignId: "",
         marketId: "",
         _isLoading: false
      };
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handlePost = this.handlePost.bind(this);
   }

   componentWillMount() {
      const token = localStorage.getItem('accessToken');
      if (!token) return;

      fetch(process.env.REACT_APP_API_URL + 'departments?sort=order', {
         method: 'get',
         headers: {
            'Authorization': token,
         }
      }).then((resp) => {
         if(resp.ok){
            return resp.json();
         }
      }).then( (respJson) => {
         let departments = [];
         respJson.docs.forEach( (department, i) => {
            departments.push({value: department._id, name: department.prettyName});
         });

         this.setState({departments});
      }).catch((error) => {
         console.log(error)
      })

   }

   handleChange(target, value) {
      this.setState({ [target]: value });
   }

   handleSubmit(e) {
      e.preventDefault(); 

      const data = {
         quarter: this.state.quarter,
         departmentId: this.state.departmentId,
         campaignId: this.state.campaignId,
         marketId: this.state.marketId,
      };

      this.handlePost(data);
   }

   handlePost(data) {
      const token = localStorage.getItem('accessToken');
      if (!token) return;

      this.setState({ _isLoading: true });

      if (data) {
         fetch(process.env.REACT_APP_SALESFORCE_MS, {
            method: 'post',
            headers: {
               'Authorization': `Bearer ${token}`,
               'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
         }).then( (response) => {
            if (response.ok) {
               return response.text()
            } else {
               return response.json().then(Promise.reject.bind(Promise));
            }
         }).then((text) => {
            toast(text, { className: "notification success"});
            this.setState({ _isLoading: false });
            this.props.handleCancel();
         }).catch((error) => {
            toast(error.message, { className: "notification error"});
            this.setState({ _isLoading: false });
         });
      }
   }

   render() {
      return (
         <Form className="form sf">
            <div className="form-header">
               <div>Salesforce Integration</div>
               <div className="buttons">
                  <button className="cancel" onClick={this.props.handleCancel}>Cancel</button>
               </div>
            </div>
            <div className="form-body">
               <div className="form-section">
                  <div className="radio-group">
                     <div className="radio-group-header">
                        <span className="label">Quarter(s)</span>
                     </div>
                     <div className="radio-group-body">
                        <RadioTools data={this.state.quarters} currentOption={this.state.quarter}
                                    handleChange={this.handleChange} type={"quarter"}/>
                     </div>
                  </div>

                  <div className="radio-group">
                     <div className="radio-group-header">
                        <span className="label">Department(s)</span>
                     </div>
                     <div className="radio-group-body">
                        <RadioTools data={this.state.departments} currentOption={this.state.departmentId}
                                    handleChange={this.handleChange} type={"departmentId"}/>
                     </div>
                  </div>
               </div>

               <div className="form-section">
                  <div className="input-wrapper half">
                     <SelectCampaign label="Campaign" id="campaign" value={this.state.campaignId} target="campaignId" onChange={this.handleChange} />
                  </div>
                  <div className="input-wrapper half">
                     <SelectMarket label="Market" id="market" value={this.state.marketId} target="marketId" onChange={this.handleChange} />
                  </div>
               </div>
               <div className="form-footer">
                  <div className={this.state._isLoading ? "sf-loader is_loading" : "sf-loader"}/>
                  <button className="button submit"
                          disabled={!this.state.departmentId || !this.state.quarter || !this.state.campaignId || this.state._isLoading} onClick={this.handleSubmit}>Update Salesforce</button>
               </div>
            </div>
         </Form>
      )
   }
}

export default SalesforceIntegration;
