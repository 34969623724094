import React, { Component } from 'react';
import './Form.css';

class Form extends Component {
   render() {
      return (
         <form className={this.props.className}>
            {this.props.children}
         </form>
      )
   }

}
export default Form;
