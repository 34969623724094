import React from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authContext } from '../../../adalConfig'
import './UploadBudgetCsv.css';

class UploadBudgetCsv extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         file: "",
         errors: null,
         errorsCount: null,
         isLoading: false
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
   }

   handleChange(e) {
      this.setState({
         errors: null,
         errorsCount: null,
         file: e.target.value
      });
   }

   handleSubmit(e) {
      if (e) e.preventDefault();

      const token = localStorage.getItem('accessToken');
      if (!token) return;
      const FISCAL_YEAR_PLAN_ID = JSON.parse(localStorage.getItem('fiscalYearPlan'))._id;

      const selectedFile = this.refs.file.files[0];

      if (selectedFile) {
         this.setState({ isLoading: true })
         let formData = new FormData();
         formData.append('file', selectedFile);

         fetch(process.env.REACT_APP_API_URL + 'budgets/csv-import', {
            method: 'post',
            headers: {
               'Authorization': token,
               'FiscalYearPlanId': FISCAL_YEAR_PLAN_ID
            },
            body: formData
         }).then((response) => {
            if (response.status === 498) {
               authContext._renewIdToken((err, token) => {
                  this.handleSubmit()
               })
            } else if (response.ok) {
               return response.json()
            } else {
               return response.json().then(Promise.reject.bind(Promise));
            }
         }).then((json) => {
            if (!json) return;
            toast("Budgets Uploaded Successfully", { className: "notification success"});
            toast("Programs Updated Successfully", { className: "notification success"});
            this.setState({ isLoading: false });
            this.props.onClose();
         }).catch((error) => {
            console.log(error);
            if (error.errors) this.setState({
               errors: error.errors,
               errorsCount: error.totalErrors,
               totalProcessed: error.totalProcessed,
               totalUpdated: error.totalUpdated
            });
            if (error.message) {
               toast("Error Uploading Budgets", { className: "notification error"});
               toast("Column Headers Incorrect", { className: "notification error"});
            }
            this.setState({ isLoading: false })
         });

      } else {

         toast("Please select a .csv file", { className: "notification error"});

      }
   }

   render() {
      return (
         <div className={this.state.isLoading ? "modal is-loading" : "modal"}>
            <h5 className="modal-header">
               Upload Budget CSV
            </h5>
            <form onSubmit={this.handleSubmit}>
               <div className="modal-body">
                  <input ref="file" type="file" value={this.state.file} onChange={this.handleChange} />
               </div>

               {this.state.errors &&
                  <div className="modal-errors">

                     <h5 className="error-header">
                        Only {this.state.totalUpdated} out of {this.state.totalProcessed} programs have been updated successfully. 
                     </h5>

                     <h5 className="error-header">
                        {this.state.errorsCount ? this.state.errorsCount : "" } error{this.state.errorsCount > 1 ? "s" : ""} found. Please correct file and re-upload.
                     </h5>

                     <ul className="error-list">
                        {this.state.errors.map(function(error, i) {
                           return (
                              <li className="error-list-item" key={i}>
                                 {error.description} <span>(row {error.row}, column {error.column})</span>
                              </li>
                           )
                        })}
                     </ul>

                  </div>
               }

               <div className="modal-actions">
                  <a href='../templates/example_budget_upload.csv' download>Download Template</a>
                  <input type="submit" value="Submit" disabled={this.state.isLoading} className="button submit" />
               </div>
            </form>
         </div>
      )
   }
}

export default withRouter(UploadBudgetCsv);
